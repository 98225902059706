.contact {
    &__hero {
        background: url('/images/contacto/hero.jpg') no-repeat center bottom / cover;
        position: relative;
        padding: 40vh 0 10vh 0;
        z-index: 4000;
        display: flex;
        align-items: center; 

        @include mq(md) {
            padding: 0;
            height: 80vh;
            padding-top: 9.6vw;
        }

        &__title {
            color: $white;
            font-size: 80px;
            margin: 0;

            @include mq(md) {
                font-size: 8vw;
            }
        }
    }

    &__info {
        padding: 50px 0;

        @include mq(md) {
            padding: 100px 0;
        }

        &__title {
            font-weight: 900;
            color: $black;
            margin: 0;
            margin-bottom: 40px;
            font-size: 30px;
            
            span {
                font-weight: 100;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }        

        &__container {
            
            @include mq(md) {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__main-title {
            width: 100%;
            font-size: 30px;
            font-weight: 900;
            margin: 0;
            margin-bottom: 40px;

            @include mq(md) {
                font-size: 40px;
            }
        }

        &__wrapper {
            width: 100%;

            @include mq(md) {
                width: 50%;
            }
        }

        &__text {
            display: block;
            text-decoration: none;
        }
    }

    &__form {
        padding: 50px 0;
        background: url('/images/contacto/middle.jpg') no-repeat center center / cover;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .7);
        }
        
        @include mq(md) {
            padding: 100px 0;
        }

        &__title {
            font-weight: 900;
            color: $white;
            margin: 0;
            margin-bottom: 40px;
            font-size: 30px;
            
            span {
                font-weight: 100;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }        

        &__main-title {
            width: 100%;
            font-weight: 100;
            margin: 0;
            margin-bottom: 40px;
            color: $white;
            font-size: 24px;

            @include mq(md) {
                font-size: 40px;
            }
        }
        
        &__text {
            color: $white;
            line-height: 1.6;
            margin-bottom: 40px;
            font-size: 14px;

            @include mq(md) {
                font-size: 16px;
            }
        }

        &__input {
            border: none;
            background: none;
            border-bottom: 1px solid $white;
            height: 50px;
            width: 100%;
            outline: none;
            margin-bottom: 30px;
            color: $white;
            
            &::placeholder {
                color: $white;
                opacity: .7;
            }

            @include mq(md) {
                margin-bottom: 0;
            }
    
            &:not(:last-child) {
                @include mq(md) {
                    margin-bottom: 20px;
                }
            }
    
            &--textarea {
                @include trans;
    
                height: 50px;
                
                @include mq(md) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
                
                &:focus {
                    height: 200px;
    
                    @include mq(md) {
                        height: 100%;
                    }
                }
            }
        }
    
        &__button {
            @include trans;

            cursor: pointer;
            display: block;
            padding: 15px 0;
            text-align: center;
            border: 1px solid $red;
            color: $red;
            font-weight: 900;
            background: none;
            width: 100%;
            margin-top: 20px;
    
            @include mq(md) {
                margin-top: 40px;
                width: 50%;
    
            }

            &:hover {
                border: 1px solid ($red - #333);
                color: ($red - #333);
            }
        }
    }

    &__location {
        padding: 50px 0 10px 0;
        background: $black;

        @include mq(md) {
            padding: 100px 0 60px 0;
        }

        &__title {
            font-weight: 900;
            color: $white;
            margin: 0;
            margin-bottom: 40px;
            font-size: 30px;

            span {
                font-weight: 100;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }        

        &__main-title {
            margin: 0;
            font-size: 20px;
            margin-bottom: 20px;
            color: $white;
            
            @include mq(md) {
                font-size: 28px;
            }
        }

        &__text {
            font-size: 16px;
            margin: 0;
            color: $white;
            margin-bottom: 20px;
            @include mq(md) {
                font-size: 20px;
            }            
        }

        &__mail {
            display: block;
            color: $white;
            margin-bottom: 40px;
            text-decoration: none;

            @include mq(md) {
                font-size: 20px;
            }            
        }
    }
}